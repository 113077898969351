import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { analyticsStore, turmaAlunoStore } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
let Desistencia = class Desistencia extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                show: true,
                table: 'turma_aluno',
                text: 'Data em que foi evadido',
                type: 'timestamp',
                value: 'data_deixou_vigencia',
            },
            {
                show: true,
                table: 'aluno',
                text: 'Aluno',
                type: 'str',
                value: 'aluno_nome',
            },
            {
                show: true,
                table: 'aluno',
                text: 'Responsável',
                type: 'str',
                value: 'responsavel_nome',
            },
            {
                show: true,
                table: 'ultima_atividade',
                text: 'Ultima unidade presente',
                type: '',
                value: 'nome_ultima_atividade_executada',
            },
            {
                show: true,
                table: 'ultima_atividade',
                text: 'Data da ultima unidade presente',
                type: 'timestamp',
                value: 'data_ultima_atividade_executada',
            },
            {
                show: true,
                table: 'ultima_atividade',
                text: 'Professor',
                type: 'str',
                value: 'professor_nome',
            },
            {
                text: 'Turma',
                value: 'codigo_referencia',
                table: 'turma',
                type: 'int',
                show: true,
            },
            {
                show: true,
                table: 'livro',
                text: 'Livro',
                type: 'str',
                value: 'nome',
            },
            {
                show: true,
                table: 'consultor',
                text: 'Consultor que matriculou',
                type: 'str',
                value: 'consultor_full_name',
                field: 'full_name',
            },
            {
                show: true,
                table: 'rota',
                text: 'Aluno na van',
                type: '',
                value: 'rota_ponto_parada_aluno_id',
            },
        ];
        this.selectPeriodDate = null;
    }
    get totals() {
        return analyticsStore.totals;
    }
    async buscar() {
        analyticsStore.setDefaultFilters({
            'turma_aluno.data_deixou_vigencia': {
                type: 'timestamp',
                value: [this.selectPeriodDate[0], this.selectPeriodDate[1]],
            },
        });
        await this.doSearchTotals();
        await analyticsStore.executeSearchItems();
    }
    async doSearchItems(queryIn) {
        return await turmaAlunoStore.getCadernoEvadidosQuery(queryIn);
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsCadernoEvadidos({
            data_inicial: this.selectPeriodDate[0],
            data_final: this.selectPeriodDate[1],
        });
    }
    async mounted() {
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(turmaAlunoStore.getCadernoEvadidosQuery);
        analyticsStore.setHeaders(this.headers);
    }
};
Desistencia = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            GenericListTotalCardComponent,
            GenericListItemsCardComponent,
            TextExpandableComponent,
            OpenNewTabComponent,
        },
    })
], Desistencia);
export default Desistencia;
